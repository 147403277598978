
import { Component, Vue, Prop } from "vue-property-decorator";
import { WorkStatus } from "@/store/modules/activity.store";
import { TeamFunctions } from "@/store/modules/activity-account.store";
import { formatCurrency } from "@/utils/formatters";

export interface DealCard extends Activity {
    saving?: boolean;
}

@Component({
    components: {
        UserImage: () => import("@/components/general/user-image.vue"),
        UserImageWrapper: () => import("@/components/general/user-image-wrapper.vue"),
        DossierCommission: () => import("@/components/dossier/commission.vue"),
    },
})
export default class DossierCardComponent extends Vue {
    @Prop({ default: () => {} }) deal!: DealCard;

    @Prop({ default: false }) showComission!: boolean;

    WorkStatus = WorkStatus;

    formatCurrency = formatCurrency;

    get uniqueAssignees() {
        return [...new Map(this.deal.assigned_to.filter((item) => item.type === TeamFunctions.seller).map((item) => [item.id, item])).values()];
    }

}
